.demoModeFullScreen {
    height: calc(100vh - 80px);
	position: fixed;
	width: 100vw;
	left: 0;
	top: 0;
	overflow-x: hidden;
	overflow-y: auto;
	background: white;
	z-index: 1060;
}

.returnLink {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
