.lessonBackdropClose {
    position: fixed;
    top: 10px;
    right: 3%;
    font-size: 3em;
    color: #FFFFFF;
}

.assignmentBackgroundText {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.assignmentBackgroundText > * {
    flex: 1;
}

.assignmentBackground {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #FFFFFF;
    z-index: 1;
}

.assignmentWalkthrough {
    position: fixed;
    height: 100vh;
    width: 100vw !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
}

.assignmentWalkthrough::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: rgba(0,0,0, 0.5);
}

.assignmentCompleted {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.assignmentContents {
    position: relative;
    flex: 1 1 auto; /* fill in remaining vertical space */
    min-height: calc(90vh - 104px - 2rem);
    padding-bottom: 10px;
    overflow-y: auto;
}

.assignmentContentsDemoMode {
    position: relative;
    flex: 1 1 auto; /* fill in remaining vertical space */
    min-height: calc(100vh - 104px - 2rem);
    padding-bottom: 10px;
    overflow-y: auto;
}

.assignmentProgress {
    background-color: #FFFFFF;
    position: sticky;
    bottom: 0;
    z-index: 2;
    align-items: center;
}

button.assignmentActionButtons {
    font-size: x-large;
    padding: 7px;
}

@media screen and (max-width: 1399px) and (min-width: 1024px) {
    button.assignmentActionButtons {
        font-size: large;
        padding: 5px;
    }
}

@media screen and (max-width: 1024px) {
    button.assignmentActionButtons {
        font-size: medium;
        padding: 5px;
    }
}

.assignmentIframe {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
}

.fitContent {
    /*
    * According to https://caniuse.com/?search=fit-content fit-content for height/width
    * is still being worked out in firefox so the prefix -moz- is needed.
    */
    height: -moz-fit-content;
    width: -moz-fit-content;
    height: fit-content;
    width: fit-content;
}

.pureInquiryBox {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height:100%;
}
