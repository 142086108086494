@use "variables";

// Put all common (reusable) styles here not specific to any portion of the app
$theme-color: variables.$theme-color;
$theme-color-2: variables.$theme-color-2;
$light: rgba(245, 245, 252, 0.98);

.theme-color {
    color: $theme-color-2 !important;
}

.full-screen {
	height: calc(100vh - 80px);
}

.min-full-screen {
	min-height: calc(100vh - 80px);
}

/* In medium screen and above, full height will be applied to make only a portion of lessons search screen scrollable. */
@media (min-width: 768px) {
	.full-screen-md  {
		height: calc(100vh - 80px);
	}
}

.input-glow {
	padding: 5px;
	border-radius: 15px;
	color: #515164;
	outline: none;
	border: none;
	box-shadow: 0 0 0 7px white, 0 0 11px 3px $theme-color-2;
}

.text-theme {
	color: $theme-color-2
}

.border-theme {
	border-color: $theme-color-2 !important
}

.padding-header {
	padding-top: 80px;
}

.font-sm {
	font-size: 14px;
}

.class-grade-background {
	background-color: rgba(63,75,188, .2);
}

@media only screen and (max-width: 767px) {
	.min-full-screen {
		height: auto;
	}
}

.editModal {
	max-height: 90vh;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.no-rounded-start {
	border-top-left-radius: 0 !important;
}

.mt-6 {
	margin-top: 5rem;
}

.move-front {
	z-index: 10;
}

.lesson-input {
	border-radius: 18px;
	color: #515164;
	line-height: 1.1;
	padding: 10px;
	outline: none;
	border: none;
	margin-top: 18px;
	margin-bottom: 12px;
	box-shadow: 0 0 0 7px white, 0 0 11px 3px $theme-color-2;
}

.iconButton {
	font-size: 1.5em;
	z-index: 1;
}

.iconButton:hover {
	color: $theme-color;
}

.center-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.blur {
	-webkit-filter: blur(4px);
	-moz-filter: blur(4px);
	-o-filter: blur(4px);
	-ms-filter: blur(4px);
	filter: blur(4px); 
}

.image-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba($light, 0.3);
}

.lockedBanner {
	width: 102%; 
	background: linear-gradient(70deg, white, $light, $light, $light, white);
	padding: 15px;
}

.expand-btn {
	@extend .iconButton;
	position: absolute;
	top: 0;
	right: 0;
	vertical-align: super;
}

.mw-hd {
	max-width: 1920px;
}

.full-screen-mode {
	@extend .full-screen;
	position: fixed;
	width: 100vw;
	left: 0;
	top: 80px;
	overflow-x: hidden;
	overflow-y: auto;
	background: white;
	z-index: 1060;
}

.inherit-flex {
	display: inherit;
	height: 100%;
	flex-direction: inherit;
	flex: 1;
}

// Formik adds this padding when the input field is invalid,
// use this to have the padding be consistent on fields.
.formik-input-padding {
	padding-right: 2.25em;
}


/* Vertical fractions */
.fraction
{
	display: inline-block;
	position: relative;
	vertical-align: middle; 
	
	letter-spacing: 0.001em;
	text-align: center;
}

.fraction > span
{ 
	display: block; 
	
	padding: 0.1em;
}
.fraction span.bottom
{
	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: #000000;
}
